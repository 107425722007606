import {
  Container,
  Button,
  Box,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';
import { CompanyLogoWide } from '@components/CompanyLogoWide';
import peopleAtTableImage from '@images/people-at-table.png';
import agentPhoneImage from '@images/agent-phone.png';
import oldWayNewWayImage from '@images/old-way-new-way.png';
import planListImage from '@images/plan-list.png';
import drugsImage from '@images/drugs.png';
import enrollmentImage from '@images/enroll.png';
import HealthSheraSecondaryLogo from '@images/HSlogo.png';
import crmImage from '@images/crm.png';
import { FEATURE_FLAGS } from '@constants/featureFlags';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { ServerError } from '@components/forms/ServerError';
import { CSRFToken } from '@components/forms/CSRFToken';
import { useState } from 'react';
import { useCreatePreLaunchLead } from '../hooks/useCreatePreLaunchLead';
import { getHandleApiError } from '@utils/forms/handleApiError';
import { CallUsLink } from '@components/ContactUs/CallUsLink';
import { EmailUsLink } from '@components/ContactUs/EmailUsLink';

const StraightLine = () => <>&nbsp;&nbsp;|&nbsp;&nbsp;</>;
const Bullet = ({ fontWeight = '400' }: { fontWeight?: string }) => (
  <Box display="inline" fontWeight={fontWeight}>
    &nbsp;&nbsp;&bull;&nbsp;&nbsp;
  </Box>
);

export function Landing() {
  const [success, setSuccess] = useState(false);
  const formContext = useForm();
  const theme = useTheme();
  const isSmallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = formContext;

  const { trigger, isMutating } = useCreatePreLaunchLead({
    onSuccess: () => {
      setSuccess(true);
      reset();
    },
    onError: getHandleApiError(setError),
  });

  const onSubmit = (data: FormData) => {
    trigger({ ...data, source: 'teaser' });
  };
  return (
    <Box
      sx={{
        mb: 20,
        display: 'flex',
        textAlign: 'center',
        minHeight: '100vh',
        justifyContent: { sm: 'center' },
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 8,
          width: '100%',
          boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.1)',
        }}
      >
        <CompanyLogoWide width={isSmallSize ? 130 : 200} height={isSmallSize ? 18 : 26} />
        {!FEATURE_FLAGS.launchEnabled ? null : (
          <Box
            sx={{
              textWrap: 'nowrap',
              fontSize: '13px',
            }}
          >
            <CallUsLink sx={{ mr: 10, textDecoration: 'none' }} />
            <Link
              href="https://intercom.help/healthsherpa-medicare-help-center/en/"
              rel="noopener"
              target="_blank"
              sx={{ mr: 10, textDecoration: 'none', display: { xs: 'none', sm: 'inline' } }}
            >
              Help center
            </Link>
            <Link sx={{ mr: 10, textDecoration: 'none' }} href="/users/new">
              Sign up
            </Link>
            <Link sx={{ textDecoration: 'none' }} href="/sessions/new">
              Log in
            </Link>
          </Box>
        )}
      </Box>
      <Container disableGutters sx={{ px: { xs: 5, sm: 40 } }}>
        <Box
          sx={{
            px: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: { xs: 0, sm: 25 },
          }}
        >
          <Box
            sx={{
              display: { xs: 'inline', sm: 'flex' },
              flexDirection: { sm: 'row-reverse' },
              flex: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '100%', sm: '50%' },
                mt: { xs: 5, sm: 0 },
                mb: { xs: 15, sm: 0 },
              }}
              src={peopleAtTableImage}
              alt="people working at a table"
            />
            <Box sx={{ alignContent: 'center', mr: { xs: 0, sm: 13 } }}>
              <Typography variant="h3" sx={{ textAlign: 'left', fontWeight: 800 }}>
                The all-in-one Medicare platform for brokers.
              </Typography>
              <Typography variant="h6" sx={{ textAlign: 'left', mt: 10 }}>
                Shop, enroll, and track clients from a single platform.
              </Typography>
              {!FEATURE_FLAGS.launchEnabled ? (
                <Card
                  variant="outlined"
                  sx={{
                    width: { sm: 450, md: 390 },
                    border: '1px solid powder.dark',
                    borderRadius: 1,
                    mt: 10,
                    backgroundColor: 'powder.light',
                  }}
                >
                  <CardContent sx={{ p: 0, '&:last-child': { px: 8, py: 6.5 } }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 800, color: 'primary.main', display: 'flex' }}
                    >
                      Launching soon! Submit your email to be notified
                    </Typography>
                    <Box>
                      <FormContainer
                        formContext={formContext}
                        handleSubmit={handleSubmit(onSubmit)}
                      >
                        <CSRFToken />
                        <ServerError sx={{ mb: '15px', mt: '30px' }} errors={errors} />
                        <Box flexDirection="row" display="flex" alignItems="baseline">
                          <TextFieldElement
                            margin="normal"
                            required
                            type="email"
                            id="email"
                            placeholder="Your email"
                            name="email"
                            autoComplete="email"
                            size="small"
                          />
                          <Button
                            size="small"
                            type="submit"
                            disabled={isMutating}
                            fullWidth
                            variant="contained"
                            sx={{ ml: 4.5, height: 38, width: 75 }}
                          >
                            Submit
                          </Button>
                        </Box>
                        {success && (
                          <Typography
                            variant="body1"
                            sx={{
                              mt: 4,
                              color: 'success.700',
                              fontWeight: 'fontWeightBold',
                              textAlign: 'center',
                            }}
                            data-cy="success-msg"
                          >
                            Thanks! We&apos;ll email you when HealthSherpa Medicare launches.
                          </Typography>
                        )}
                      </FormContainer>
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                <Box sx={{ maxWidth: 250, width: 200, alignSelf: 'flex-start', mt: 10, mb: 5 }}>
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    variant="contained"
                    href="/users/new"
                    sx={{ textWrap: 'nowrap', height: 50 }}
                  >
                    Get your free account
                  </Button>
                </Box>
              )}
              <Box sx={{ display: 'flex', alignSelf: 'flex-start', mt: 10.5 }}>
                {!FEATURE_FLAGS.launchEnabled ? (
                  <Link
                    href="https://intercom.help/healthsherpa-medicare-help-center/en/articles/9673593-agent-training"
                    rel="noopener"
                    target="_blank"
                  >
                    Sign up for a webinar
                  </Link>
                ) : (
                  <>
                    <Link
                      href="https://intercom.help/healthsherpa-medicare-help-center/en/articles/9667585-product-overview-demo"
                      rel="noopener"
                      target="_blank"
                    >
                      Product demo
                    </Link>
                    <StraightLine />
                    <Link
                      href="https://intercom.help/healthsherpa-medicare-help-center/en/articles/9673593-webinars"
                      rel="noopener"
                      target="_blank"
                    >
                      Webinars
                    </Link>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: 0, sm: 31 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                mr: { xs: 0, sm: 15 },
              }}
            >
              <Typography variant="h5" sx={{ mt: 15, fontWeight: 800, color: 'primary.main' }}>
                FMO neutral
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left', mt: 3 }}>
                We&apos;re not an FMO. So no matter which FMO uplines you have, you can use our
                platform.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                mr: { xs: 0, sm: 15 },
              }}
            >
              <Typography
                variant="h5"
                sx={{ alignSelf: 'flex-start', mt: 15, fontWeight: 800, color: 'primary.main' }}
              >
                Multi-carrier
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left', mt: 3 }}>
                Access your carriers in one place. No need to bounce between various platforms.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h5"
                sx={{ alignSelf: 'flex-start', mt: 15, fontWeight: 800, color: 'primary.main' }}
              >
                No cost
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left', mt: 3 }}>
                Free for agents and agencies. Our optional referrals program keeps it that way.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              borderRadius: 2,
              p: 8.5,
              pt: 9.5,
              mt: { xs: 15, sm: 31 },
              display: { xs: 'inline', sm: 'flex' },
            }}
          >
            <Box
              component="img"
              sx={{
                display: 'flex',
                mb: 6,
                width: { xs: '75%', sm: '25%' },
              }}
              src={agentPhoneImage}
              alt="agent talking on phone"
            />
            <Box sx={{ alignContent: 'center', ml: { xs: 0, sm: 14 }, pr: { xs: 0, sm: 25 } }}>
              <Typography
                variant="h5"
                sx={{
                  alignSelf: 'flex-start',
                  fontWeight: 800,
                  color: 'white',
                  textAlign: 'left',
                  mb: 5,
                }}
              >
                Trusted by 50,000 brokers. Now transforming Medicare.
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left', color: 'white' }}>
                We&apos;ve built the largest ACA platform for brokers, processing over 10 million
                annual enrollments. Now we&apos;re bringing that experience to Medicare.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%',
              mt: { xs: 15, sm: 27 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1,
                pr: { xs: 0, sm: 42 },
              }}
            >
              <Typography
                variant="h5"
                sx={{ textAlign: 'left', fontWeight: 800, color: 'primary.main' }}
              >
                FMO neutral
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Works with all your uplines—we’re not an FMO
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Our integrations pull your Ready-to-Sell status
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                It’s your book of business, your data, your platform
              </Typography>
            </Box>
            <Box
              component="img"
              sx={{
                width: { xs: '100%', sm: '50%' },
                pt: 9,
                maxWidth: 516,
              }}
              src={oldWayNewWayImage}
              alt="A comparison of the old way and the new way of enrolling in Medicare"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: 15, sm: 27 },
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1,
                pr: { xs: 0, sm: 42 },
              }}
            >
              <Typography
                variant="h5"
                sx={{ textAlign: 'left', fontWeight: 800, color: 'primary.main' }}
              >
                Shop plans across carriers
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Shop MAPD, MA, Part D, and SNPs
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Filter by plan benefits
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                <Box display="inline" fontWeight="800">
                  Coming soon:{' '}
                </Box>
                Med Supp
              </Typography>
            </Box>
            <Box
              component="img"
              sx={{
                width: { xs: '100%', sm: '50%' },
                pt: 9,
                maxWidth: 516,
              }}
              src={planListImage}
              alt="A screenshot of the app displaying a list of available plans"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: 15, sm: 27 },
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1,
                pr: { xs: 0, sm: 42 },
              }}
            >
              <Typography
                variant="h5"
                sx={{ textAlign: 'left', fontWeight: 800, color: 'primary.main' }}
              >
                Check drugs & providers
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                View costs per drug, per pharmacy, and estimated total costs
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Check provider network status per location
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mt: 3,
                  textWrap: 'pretty',
                  textAlign: 'left',
                  pl: 11,
                  textIndent: -22,
                }}
              >
                <Bullet />
                Filter to plans with your drugs and providers in network
              </Typography>
            </Box>
            <Box
              component="img"
              sx={{
                width: { xs: '100%', sm: '50%' },
                pt: 9,
                maxWidth: 516,
              }}
              src={drugsImage}
              alt="A screenshot of the app displaying a user selecting prescriptions"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: 15, sm: 27 },
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1,
                pr: { xs: 0, sm: 42 },
              }}
            >
              <Typography
                variant="h5"
                sx={{ textAlign: 'left', fontWeight: 800, color: 'primary.main' }}
              >
                Easy enrollment
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Pre-filled forms to reduce data entry
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Text, email, and voice signature
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Carrier and CMS approved forms
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Access HRAs for select carriers
              </Typography>
            </Box>
            <Box
              component="img"
              sx={{
                width: { xs: '100%', sm: '50%' },
                pt: 9,
                maxWidth: 516,
              }}
              src={enrollmentImage}
              alt="A screenshot of the app displaying a user filling out enrollment information"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              mt: { xs: 15, sm: 27 },
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1,
                pr: { xs: 0, sm: 42 },
              }}
            >
              <Typography
                variant="h5"
                sx={{ textAlign: 'left', fontWeight: 800, color: 'primary.main' }}
              >
                Track your book
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mt: 3,
                  textWrap: 'pretty',
                  textAlign: 'left',
                  pl: 11,
                  textIndent: -22,
                }}
              >
                <Bullet />
                View all contacts in one easy CRM
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Save drugs and providers to contact profiles
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 3, textWrap: 'pretty', textAlign: 'left', pl: 11, textIndent: -22 }}
              >
                <Bullet />
                Browse enrollment history per contact
              </Typography>
            </Box>
            <Box
              component="img"
              sx={{
                width: { xs: '100%', sm: '50%' },
                pt: 9,
                maxWidth: 516,
              }}
              src={crmImage}
              alt="A screenshot of the app displaying a user's client list"
            />
          </Box>
          {!FEATURE_FLAGS.launchEnabled ? null : (
            <>
              <Typography
                variant="h3"
                sx={{ fontWeight: 800, mt: { xs: 31, sm: 37 }, maxWidth: 395 }}
              >
                Sign up for HealthSherpa Medicare today
              </Typography>
              <Box sx={{ mt: { xs: 0, sm: 10 } }}>
                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  href="/users/new"
                  variant="contained"
                  sx={{ mt: 5, mb: 4, textWrap: 'nowrap', height: 50 }}
                >
                  Get your free account
                </Button>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignSelf: 'flex-start',
              alignItems: 'flex-start',
              mt: { xs: 35, sm: 43 },
              width: '100%',
            }}
          >
            <Box sx={{ width: '40%', display: 'flex' }}>
              <Box
                component="img"
                sx={{
                  maxWidth: 202,
                  pr: 5,
                  width: '100%',
                  height: 'auto',
                  display: { xs: 'none', sm: 'inline' },
                }}
                src={HealthSheraSecondaryLogo}
                alt="Company logo for HealthShera"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box
                sx={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  display: 'flex',
                  flex: 1,
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 800 }}>
                  Support
                </Typography>
                {!FEATURE_FLAGS.launchEnabled ? null : (
                  <>
                    <Link
                      href="https://intercom.help/healthsherpa-medicare-help-center/en/"
                      rel="noopener"
                      target="_blank"
                      sx={{ pt: 5 }}
                    >
                      Help center
                    </Link>
                    <Link
                      href="https://intercom.help/healthsherpa-medicare-help-center/en/articles/9673593-webinars"
                      rel="noopener"
                      target="_blank"
                      sx={{ pt: 5 }}
                    >
                      Webinars
                    </Link>
                  </>
                )}
                <EmailUsLink sx={{ pt: 5 }}>Contact us</EmailUsLink>
              </Box>
              <Box
                sx={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  display: 'flex',
                  mt: { xs: 15, sm: 0 },
                  flex: 1,
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 800 }}>
                  Platforms
                </Typography>
                <Link href="https://healthsherpa.com" rel="noopener" target="_blank" sx={{ pt: 5 }}>
                  ACA
                </Link>
                <Link href="/" rel="noopener" target="_blank" sx={{ pt: 5 }}>
                  Medicare
                </Link>
              </Box>
              <Box
                sx={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  display: 'flex',
                  mt: { xs: 15, sm: 0 },
                  flex: 1,
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 800 }}>
                  Follow us
                </Typography>
                <Link
                  href="https://www.linkedin.com/company/healthsherpa/mycompany/"
                  rel="noopener"
                  target="_blank"
                  sx={{ pt: 5 }}
                >
                  Linkedin
                </Link>
                <Link
                  href="https://x.com/healthsherpas"
                  rel="noopener"
                  target="_blank"
                  sx={{ pt: 5 }}
                >
                  X.com
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ borderTop: '1px solid #D3E0E8', mt: 18 }}>
          <Typography
            variant="body2"
            sx={{ fontSize: 10, mt: 8, color: 'grey.700', textAlign: 'left' }}
          >
            © HealthSherpa 2024. HealthSherpa is not affiliated or endorsed by CMS or the Federal
            Medicare Program. Contact us at{' '}
            <CallUsLink sx={{ color: 'grey.700', textDecoration: 'none' }} /> or{' '}
            <EmailUsLink sx={{ color: 'grey.700', textDecoration: 'none' }} />
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 10, color: 'grey.700', textAlign: 'left' }}>
            <Link
              href="/devoted_terms_of_service"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'grey.700' }}
            >
              Additional Disclaimers
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
